<template>
  <div class="uk-margin-top uk-margin-bottom">
    <div class="uk-card uk-card-default kandang-card">
      <h1 class="text-medium">
        Pemakaian Sapronak & Return Barang
      </h1>
      <div class="uk-margin-bottom">
        <ContainerDOC :detail-rhpp="isCalculated ? dataCalculateRhpp : detailRhpp" />
      </div>
      <div class="uk-margin-bottom">
        <ContainerPakan :detail-rhpp="isCalculated ? dataCalculateRhpp : detailRhpp" />
      </div>
      <div class="uk-margin-bottom">
        <ContainerOVK :detail-rhpp="isCalculated ? dataCalculateRhpp : detailRhpp" />
      </div>
      <div class="uk-margin-bottom">
        <ContainerReturnGoods :detail-rhpp="isCalculated ? dataCalculateRhpp : detailRhpp" />
      </div>

      <div
        v-if="isDetail"
        class="uk-grid-small uk-child-width-1-3@xl uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-2@s  uk-child-width-1-1 uk-flex-right"
        uk-grid
      >
        <div class="uk-margin-bottom">
          <div class="upload-card">
            <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom text-medium border-0">
              {{ dataDetail.head_title || 'KEPALA CABANG' }}
            </div>
            <div
              class="uk-width-1-1 uk-text-center uk-margin-small-bottom js-upload border-0"
              uk-form-custom
            >
              <img
                v-lazy="dataDetail.approval_status_head_branch == 1 ? ( dataDetail.branch_head_sign ? `${image_url}${dataDetail.branch_head_sign}` : `${images}/img-not-found.png` ) : `${images}/icon/incorrect.svg`"
                class="preview-image"
                alt="sign kepcab"
                uk-img
              >
            </div>
            <div class="uk-width-1-1 uk-text-center uk-margin-auto text-medium truncate border-0">
              {{ dataDetail.branch_head_name }}
            </div>
          </div>
        </div>
        <div class="uk-margin-bottom">
          <div class="upload-card">
            <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom text-medium border-0">
              {{ dataDetail.staff_title || 'ADMIN SALES' }}
            </div>
            <div
              class="uk-width-1-1 uk-text-center uk-margin-small-bottom js-upload border-0"
              uk-form-custom
            >
              <img
                v-lazy="dataDetail.approval_status_data_analyst == 1 ? ( dataDetail.data_analyst_sign ? `${image_url}${dataDetail.data_analyst_sign}` : `${images}/img-not-found.png`) : `${images}/icon/incorrect.svg`"
                class="preview-image"
                alt="sign analist"
                uk-img
              >
            </div>
            <div class="uk-width-1-1 uk-text-center uk-margin-auto text-medium truncate border-0">
              {{ dataDetail.data_analyst_name }}
            </div>
          </div>
        </div>
        <div class="uk-margin-bottom">
          <div class="upload-card">
            <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom border-0 text-medium">
              Mitra
            </div>
            <div
              class="uk-width-1-1 uk-text-center uk-margin-small-bottom js-upload border-0"
              uk-form-custom
            >
              <img
                v-lazy="dataDetail.approval_status_partner == 1 ? ( dataDetail.partner_sign ? `${image_url}${dataDetail.partner_sign}` : `${images}/img-not-found.png` ) : `${images}/icon/incorrect.svg`"
                class="preview-image"
                alt="sign analist"
                uk-img
              >
            </div>
            <div class="uk-width-1-1 uk-text-center uk-margin-auto text-medium truncate border-0">
              {{ dataDetail.cage_name }}
            </div>
          </div>
        </div>
      </div>

      <template v-if="dataDetail.approval_status_partner == 2">
        <p class="uk-text-right label-red">
          Alasan Tidak Setuju : {{ dataDetail.reason_partner || '-' }}
        </p>
      </template>

      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-4@s uk-width-1-4@m uk-width-1-4@l uk-width-1-2@xl">
          <router-link
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            to="/admin/rhpp"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt="icon close"
              class="uk-margin-small-right"
            >
            Kembali
          </router-link>
        </div>
        <div class="uk-width-1-1 uk-width-3-4@s uk-width-3-4@m uk-width-3-4@l uk-width-1-2@xl uk-text-right">
          <button
            v-if="roleTarget"
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/${disabling ? 'trash-white' : 'close'}.svg`"
              alt="icon close"
              class="uk-margin-small-right"
            >
            {{ disabling ? 'Hapus' : 'Batal' }}
          </button>

          <button
            v-if="isAdd"
            class="uk-button uk-button-primary uk-margin-left"
            type="button"
            @click="showSaveConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            <span
              v-if="is_loading"
              uk-spinner
              class="button-spinner"
            />Simpan
          </button>

          <button
            v-if="!isAdd && !isDetail"
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="showSaveConfirmModal(dataDetail.id)"
          >
            <img
              v-lazy="`${images}/icon/edit.svg`"
              alt="icon save"
              class="uk-margin-small-right"
            >
            {{ disabling ? 'Ubah' : 'Simpan' }}
          </button>

          <button
            v-if="isDetail && (roleTarget && disabling)"
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-left-med uk-margin-right-med uk-margin-remove-left@s uk-margin-remove-right@s uk-width-expand uk-width-auto@s"
            type="button"
            :disabled="isApproved"
            @click="handleApproval"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt="icon save"
              class="uk-margin-small-right"
            >
            Setujui
          </button>

          <button
            v-if="isDetail"
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="handleExport"
          >
            <span
              v-if="isLoading"
              uk-spinner
              class="icon-spinner-white"
            />
            <img
              v-else
              v-lazy="`${images}/icon/print.svg`"
              alt="icon print"
              class="uk-margin-small-right"
            >
            Export
          </button>
        </div>
      </div>
    </div>
    <modal-delete-confirm />
    <modal-delete-failed />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { 
  ifFalsyReturnZero, 
  thousandSeparatorFloat, 
  float2Decimals,
  capitalize, 
  roundedDecimalsSwapComma, thousandSeparator, summaryTwoTarget, currency, float0Decimals, float3Decimals, summaryOneTarget, roundedToFloat3Decimals } from '@/utils/formater'
import ContainerDOC from './ComponentSapronakAndReturn/ContainerDOC'
import ContainerOVK from './ComponentSapronakAndReturn/ContainerOVK'
import ContainerPakan from './ComponentSapronakAndReturn/ContainerPakan'
import ContainerReturnGoods from './ComponentSapronakAndReturn/ContainerReturnGoods'
import { getUserProfile } from '@/utils/auth'
import ModalDeleteConfirm from '../../list/ModalDeleteConfirm'
import ModalDeleteFailed from '../../list/ModalDeleteFailed'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {
  notificationSuccess, stringNotifDanger
} from '@/utils/notification'

export default {
  components: {
    ContainerDOC,
    ContainerOVK,
    ContainerPakan,
    ContainerReturnGoods,
    ModalDeleteConfirm,
    ModalDeleteFailed
  },
  props: {
    disabling : {
      required: true,
      type: Boolean
    },
    detailRhpp: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      isLoading: false,
      disablingPrint: false,
      formData: {},
      dataDetail: {},
      roleTarget: false,
      isDetail: false,
      isApproved: false,
      codeApproveHeadBranch: 1,
      dataExport: {},
      pathName: this.$route.fullPath,
      titleExcel: [
        {
          value: 'Formulir Isian Rekapitulasi Hasil Produksi Peternak (RHPP)'
        }, {
          value: '1. Informasi Peternak, RHPP , dan Siklus Pemeliharaan'
        }, {
          value: '2. Hasil Produksi'
        }, {
          value: '3. Pemakaian DOC'
        }, {
          value: '4. Pemakaian Pakan'
        }, {
          value: '5. Pemakaian Obat-obatan Vaksin dan Kimia (OVK)'
        }, {
          value: '6. Retur Barang'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      downloadPdfRhpp: 'rhpp/downloadPdfRhpp',
      dataRhpp: 'rhpp/detail',
      dataApprovalRhpp: 'rhpp/dataApprovalRhpp',
      dataCalculateRhpp: 'rhpp/dataCalculateRhpp',
      modalAddCalculate: 'rhpp/modalAddCalculate',
      isCalculated: 'rhpp/isCalculated'
    })
  },
  watch: {
    detailRhpp() {
      if (this.detailRhpp) {
        this.formData = this.detailRhpp
      }
    },
    dataRhpp() {
      if (this.dataRhpp) {
        if (this.dataRhpp.data) {
          this.dataDetail = this.dataRhpp.data.rhpp
          // For validate button approve head branch
          if (this.dataDetail.approval_status_head_branch == 1) {
            this.isApproved = true
          } else {
            this.isApproved = false
          }

          // For validate button print pdf
          if ((this.dataDetail.approval_status_head_branch == 1) && (this.dataDetail.approval_status_data_analyst == 1) && (this.dataDetail.approval_status_partner == 1)) {
            this.disablingPrint = false
          } else {
            this.disablingPrint = true
          }
        }
      }
    },
    dataCalculateRhpp() {
      if (this.dataCalculateRhpp) {
        this.dataExport = this.dataCalculateRhpp
      }
    }
  },
  mounted() {
    const dataProfile = getUserProfile()
    if (dataProfile) {
      this.roleTarget = dataProfile.role == 'Super Admin'
      this.isDetail = this.$route.fullPath.includes('detail')
      this.isAdd = this.$route.fullPath.includes('add')
      this.isEdit = this.$route.fullPath.includes('edit')
    }
  },
  methods: {
    ...mapActions({
      getPrintPdfRhpp: 'rhpp/getPrintPdfRhpp',
      getApproval: 'rhpp/getApproval',
      getRhppDetail: 'rhpp/getRhppDetail'
    }),
    ...mapMutations({
      setModalAdd: 'farm/SET_MODAL_ADD',
      setModalDelete: 'rhpp/SET_MODAL_DELETE'
    }),
    summaryTwoTarget(value, target1, target2) {
      return summaryTwoTarget(value, target1, target2)
    },
    roundedDecimalsSwapComma(value){
      return roundedDecimalsSwapComma(value)
    },
    ifFalsyReturnZero(variable){
      return ifFalsyReturnZero(variable)
    },
    roundedToFloat3Decimals(value, limit) {
      return roundedToFloat3Decimals(value, limit)
    },
    float3Decimals(value) {
      return float3Decimals(value)
    },
    capitalize(string) {
      return capitalize(string)
    },
    float2Decimals(value) {
      return float2Decimals(value)
    },
    summaryOneTarget(value, target) {
      return summaryOneTarget(value, target)
    },
    goToBack() {
      if (this.isDetail) {
        window.UIkit.modal('#modal-delete-confirm').show()
        this.setModalDelete(this.dataDetail)
      } else {
        window.UIkit.modal('#modal-out-confirm').show()
      }
    },
    showSaveConfirmModal(id) {
      if (id){
        if (!this.isDetail) {
          this.$validator.validateAll().then((success) => {
            if (success) {
              if (this.$validator.errors.any()) return
              this.setModalAdd(this.formData)
              if(!this.isCalculated){
                stringNotifDanger('Pastikan telah dihitung')
              } else {
                window.UIkit.modal('#modal-save-confirm').show()
              }
            }
          })} else {
          window.location.href = `/admin/rhpp/edit/${id}`
        }
      } else {
        this.$validator.validateAll().then((success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            this.setModalAdd(this.detailRhpp)
            window.UIkit.modal('#modal-save-confirm').show()
          }
        })
      }
    },
    changeLimit(e) {
      this.setMetaResume({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMetaResume({
        ...this.meta,
        page: value
      })
    },
    setCurrency(data) {
      const newNum = float0Decimals(data)
      return currency(newNum)
    },
    thousandSeparator(value) {
      return thousandSeparator(value)
    },
    thousandSeparatorFloat(value, decimal) {
      return thousandSeparatorFloat(value, decimal)
    },
    async handleExport() {
      window.UIkit.modal('#modal-choose-export').show()
    },
    async handleApproval() {
      const formData = new FormData()
      if (this.dataDetail) {
        formData.append('rhpp_id', this.dataDetail.id)
        formData.append('approval_status_data_analyst', this.dataDetail.approval_status_data_analyst)
        formData.append('approval_status_head_branch', this.codeApproveHeadBranch)
        formData.append('approval_status_partner', this.dataDetail.approval_status_partner)
        formData.append('reason_partner', this.dataDetail.reason_partner)

        await this.getApproval(formData)
        if (this.dataApprovalRhpp.message == 'OK') {
          notificationSuccess(`Approval Berhasil`)
          this.getRhppDetail(this.$route.params.id)
        }
      }
    }
  }
}

</script>

<style scoped>

  h1 {
    color: #1F2E28;
    font-size: 14px;
  }

  .table-header {
    vertical-align: inherit;
  }

  .uk-text-center {
    border-left: 1px solid #B0DFCC;
    border-right: 1px solid #B0DFCC;
  }

  .act-std {
    padding: 0;
    height: 24px;
  }

  .border-0 {
    border: 0px;
  }

  .preview-image {
    width: auto;
    height: 150px;
    object-fit: cover;
    padding: 10px;
  }

  .icon-spinner-white {
    color: #ffffff;
  }

</style>
